<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="原手機號">
        {{form.mobile}}
      </a-form-model-item>
      <a-form-model-item label="手機號" prop="mobile" >
        <a-input v-model="mobile" placeholder="请输入手機號" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="備注" prop="remark" >
        <a-input type="textarea" v-model="remark" placeholder="请输入備注" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, changeMobile } from '@/api/user/user'
import Editor from '@/components/Editor'
import {delTable} from "@/api/tool/gen";
import request from "@/utils/request";

export default {
  name: 'ChangeMobileForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      resourceType: null,
      resourceId: null,
      formTitle: '',
      mobile:null,
      remark:null,
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        resourceId: null,

        resourceType: null,

        processStatus: 0,

        processResult: null,

        createTime: null,

        remark: null,
        noticeStatus:0,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用戶id，外键{user.id}不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        resourceId: null,
        resourceType: null,
        processStatus: 0,
        processResult: null,
        createTime: null,
        remark: null,
        noticeStatus:0,
      }
      this.mobile = null;
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {

      this.resourceId = row.resourceId;

      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改手机号'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      let that = this;
      let mobile = this.mobile;
      if (mobile == null || mobile == '') {
        that.$message.error(
          '手機號不能为空',
          3
        )
        return;
      }
      const regex = /^1[3-9]\d{9}$/;
      if(!regex.test(mobile)){
        that.$message.error(
          '手機號格式不正確',
          3
        )
        return;
      }
      that.$refs.form.validate(valid => {
        if (valid) {
          that.submitLoading = true
          if (that.form.id !== undefined && that.form.id !== null) {
            let data = {
              "userId":that.form.id,
              "mobile":that.mobile,
              "remark":that.remark
            }
            changeMobile(data).then(response => {
              that.$message.success(
                '修改成功',
                3
              )
              that.open = false
              that.$emit('ok')
            }).finally(() => {
              that.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
